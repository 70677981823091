import React, { FC, ReactElement, Suspense, lazy } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Button, ButtonSize, ButtonType, Card, LinkIconAnimation } from "@qwilr/kaleidoscope";
import Link from "../components/Link/Link";
import { ArrowRight, Edit, IconSize, Styles } from "@qwilr/kaleidoscope/icons";
import { getFirstPostsByCategory } from "../utils/posts";
import { ReactComponent as Components } from "../assets/components.svg";
import { ReactComponent as Notion } from "../assets/notion.svg";
import { ReactComponent as Figma } from "../assets/figma.svg";
import { ReactComponent as Storybook } from "../assets/storybook.svg";
import { formatDistanceToNow } from "date-fns";
import { Heading, Text, Label } from "@qwilr/kaleidoscope";
import "@qwilr/kaleidoscope/vanilla-extract.css";

const IntroScene = lazy(() => import("../components/IntroScene/IntroScene"));

interface IHomeProps {
  data: {
    allMdx: {
      nodes: any;
    };
    site: {
      buildTime: any;
    };
  };
}

const Home: FC<IHomeProps> = ({ data }) => {
  const categoryLinks = getFirstPostsByCategory(data.allMdx.nodes);
  const isSSR = typeof window === "undefined";

  return (
    <div className="kld-home">
      <Helmet>
        <title>Kaleidoscope</title>
        <meta name="description" content="Qwilr’s design system" />
      </Helmet>
      <div className="kld-home__content">
        <div className="kld-home__header">
          <div className="kld-home__header-text">
            <Heading strong level="1">
              Kaleidoscope
            </Heading>
            <Text size="xl">
              Qwilr’s design system for rapidly delivering simple, consistent, and considered experiences to customers.
            </Text>
          </div>
          <div className="kld-home__header-image">
            {!isSSR && (
              <Suspense fallback={null}>
                <IntroScene />
              </Suspense>
            )}
          </div>
        </div>
        <div className="kld-home__cards">
          <Label secondary element="h2">
            Guides
          </Label>
          <div className="kld-home__cards-content kld-home__cards-content--2-col">
            <HomeCard
              icon={<Styles />}
              title="Design"
              description="Explore the foundational color, typography, iconography, and accessibility elements that define
              Kaleidoscope."
              url={categoryLinks.design.url}
              linkText="Design guides"
            />
            <HomeCard
              icon={<Components />}
              title="Components"
              description="Guidelines and best practices for the components (patterns and pieces) that make up Kaleidoscope."
              url={categoryLinks.components.url}
              linkText="Component guides"
            />
          </div>
        </div>

        <div className="kld-home__cards">
          <Label secondary element="h2">
            Resources
          </Label>
          <div className="kld-home__cards-content">
            <HomeCard
              icon={<Figma />}
              title="Figma library"
              description="Our Figma library for designing with Kaleidoscope components."
              url="https://www.figma.com/file/XwL8OGDkdNLEEZEulwayfcSq/Kaleidoscope-Components?node-id=4440%3A71"
              linkText="View Figma library"
            />
            <HomeCard
              icon={<Storybook />}
              title="Storybook"
              description="Kaleidoscope components currently in production."
              url="https://storybook.qwilr.com/dev/?path=/story/kaleidoscope"
              linkText="Storybook components"
            />
            <HomeCard
              icon={<Notion />}
              title="Notion hub"
              description="Task boards, projects, and work in progress Kaleidoscope docs."
              url="https://www.notion.so/qwilr/Kaleidoscope-aba1c52719f34c5bb59605abf24573a5"
              linkText="Notion hub"
            />
          </div>
        </div>
      </div>
      <footer className="kld-home__footer">
        <div className="kld-home__footer-content">
          <div className="kld-home__footer-edited">
            <Text secondary size="s">
              Last updated {formatDistanceToNow(new Date(data.site.buildTime))} ago
            </Text>
          </div>
          <Button
            type={ButtonType.Secondary}
            size={ButtonSize.Small}
            href={() => `https://github.com/qwilr/kaleidoscope-docs/`}
          >
            <Edit size={IconSize.Small} />
            Edit this page
          </Button>
        </div>
      </footer>
    </div>
  );
};

interface IHomeCardProps {
  icon: ReactElement;
  linkText: string;
  url: string;
  title: string;
  description: string;
}

const HomeCard: FC<IHomeCardProps> = ({ linkText, url, title, description, icon }) => (
  <Card className="kld-home__card">
    <Heading className="kld-home__card-title" level="4">
      {icon}
      {title}
    </Heading>
    <Text size="l">{description}</Text>
    <Text size="l">
      <Link href={url} iconEnd={<ArrowRight />} iconAnimation={LinkIconAnimation.SlideEnd}>
        {linkText}
      </Link>
    </Text>
  </Card>
);

export const pageQuery = graphql`
  query HomeQuery {
    allMdx {
      nodes {
        frontmatter {
          title
        }
        parent {
          ... on File {
            relativeDirectory
          }
        }
        slug
      }
    }
    site {
      buildTime
    }
  }
`;

export default Home;
